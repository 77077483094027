import React from 'react';
import styled from '@emotion/styled';
import { StaticQuery, graphql } from 'gatsby';

const StyledCreditsDiv = styled('div')`
  width: 100%;
  background-color: #f8f8f8;
  font-size: 0.8em;
`;

const Credits = ({ metaAuthor, metaVersion, metaDate }) => (
  <StyledCreditsDiv>
    {metaAuthor ? (
      <div>
        <strong>Author:</strong> {metaAuthor}
      </div>
    ) : (
      ''
    )}
    {metaDate ? (
      <div>
        <strong>Last Update:</strong> {metaDate}
      </div>
    ) : (
      ''
    )}

    {metaDate ? (
      <div>
        <strong>Version:</strong> {metaVersion}
      </div>
    ) : (
      ''
    )}
  </StyledCreditsDiv>
);

export default Credits;
