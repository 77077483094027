import React, { useState } from 'react';
// import axios from 'axios';
// const qs = require('qs');
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import ReactDOM from 'react-dom';
import QRCode from 'react-qr-code';
// import { ReactjsDimensions } from 'styled-icons/remix-fill';
// import { TruckDimensions } from 'styled-icons/fa-solid';

const qrForm = (props) => {
  const [relyingPartyId, setRelyingPartyId] = useState('');
  const [appSpaceName, setAppSpaceName] = useState('');
  const [token, setToken] = useState('');
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [qr, setQr] = React.useState('');
  const [error, setError] = React.useState('');

  const handleClose = async () => {
    setQr('');
    setIsOpen(false);
    setToken('');
  };

  const showError = async (msg) => {
    setError(msg);
    setTimeout(() => setError(''), 5000);
  };

  const handleSubmit = async (e) => {
    setError('');
    e.preventDefault();
    setQr(`authwallet://st=${token}&appSpaceGuid=${relyingPartyId}&appSpaceName=${appSpaceName}`);
    console.log(qr);
    setIsOpen(true);

    // Calling api from front-end
    // var data;
    // try {
    //   setError('');
    //   e.preventDefault();
    //   axios({
    //     method: 'post',
    //     url: 'https://cloud.privakey.com/api/account/bind',
    //     data: qs.stringify({
    //       acountId: appSpaceId,
    //     }),
    //     auth: {
    //       username: appSpaceId,
    //       password: appSpaceSecret,
    //     },
    //     headers: {
    //       'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
    //     },
    //   }).then(
    //     res => {
    //       console.log(res);
    //       setToken(res.data.sessionToken);
    //       setAppSpaceName(res.data.appSpaceName);
    //       setIsOpen(true);
    //     },
    //     rej => {
    //       console.log('rej: ' + rej);
    //       showError(rej.message);
    //     }
    //   );
    // } catch (err) {
    //   console.log('Error: ' + err);
    //   showError(err.message);
    // }
  };

  return (
    <div>
      <Form className="form" onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Label>Session Token:</Form.Label>
          <Form.Control
            type="text"
            name="token"
            value={token}
            onChange={(e) => setToken(e.target.value)}
            placeholder="sessionToken"
          />{' '}
        </Form.Group>{' '}
        <Form.Group>
          <Form.Label>App Space GUID:</Form.Label>
          <Form.Control
            type="text"
            name="relyingPartyId"
            value={relyingPartyId}
            onChange={(e) => setRelyingPartyId(e.target.value)}
            placeholder="appSpaceGuid"
          />{' '}
        </Form.Group>{' '}
        <Form.Group>
          <Form.Label>App Space Name:</Form.Label>
          <Form.Control
            type="text"
            name="appSpaceName"
            value={appSpaceName}
            onChange={(e) => setAppSpaceName(e.target.value)}
            placeholder="appSpaceName"
          />{' '}
        </Form.Group>
        <Button type="submit" variant="secondary">
          Submit!
        </Button>
      </Form>
      {error && (
        <Alert className="my-4" key={Date.now()} variant="danger">
          {error}
        </Alert>
      )}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        show={modalIsOpen}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Scan to bind the application</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <div>
            <QRCode value={qr} />
          </div>
          <p>
            <small> Open the Privakey Auth Wallet Application, click Add Service, and Scan</small>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default qrForm;
