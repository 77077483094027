const config = {
  gatsby: {
    siteUrl: 'https://tech.privakey.com',
    gaTrackingId: null,
    trailingSlash: false,
  },
  header: {
    logo: '',
    logoLink: '',
    title: 'Privakey Tech',
    githubUrl: '',
    helpUrl: '',
    tweetText: '',
    social: ``,
    links: [{ text: '', link: '' }],
    search: {
      enabled: false,
      indexName: '',
      algoliaAppId: process.env.GATSBY_ALGOLIA_APP_ID,
      algoliaSearchKey: process.env.GATSBY_ALGOLIA_SEARCH_KEY,
      algoliaAdminKey: process.env.ALGOLIA_ADMIN_KEY,
    },
  },
  sidebar: {
    forcedNavOrder: ['/', '/authWallet'],
    links: [
      { text: 'Privakey', link: 'https://privakey.com' },
      { text: 'Privakey Docs', link: 'https://docs.privakey.com' },
    ],
    frontline: false,
    ignoreIndex: false,
    title: '',
  },
  siteMetadata: {
    title: 'Privakey Tech',
    description: 'Guides, Tutorials, and other stuff',
    ogImage: null,
    docsLocation: '',
    favicon: '',
  },
  pwa: {
    enabled: false, // disabling this will also remove the existing service worker.
    manifest: {
      name: 'Privakey Tech',
      short_name: 'PkDocs',
      start_url: '/',
      background_color: '#F27901',
      theme_color: '#F27901',
      display: 'standalone',
      crossOrigin: 'use-credentials',
      // icons: [
      //   {
      //     src: 'src/pwa-512.png',
      //     sizes: `512x512`,
      //     type: `image/png`,
      //   },
      // ],
    },
  },
};

module.exports = config;
